@import '../../../../scss/theme-bootstrap';

// new for 2018 Creative Refresh
.product-brief__shades {
  width: 294px;
  margin: 0 auto;
  @include breakpoint($medium-up) {
    max-width: 210px;
    // @todo this is hacky so we can show the error when only a couple shades.
    min-width: 210px;
    margin: 0 15px;
  }
  .slick-prev,
  .slick-next,
  .slick-prev:before,
  .slick-next:before {
    font-size: 20px;
    line-height: 1;
    color: $color-black;
  }
  .slick-prev {
    @include bordered-triangle(12px, $color-white, left, $color-black);
    left: -20px;
    top: 27px;
    position: absolute;
    &:before {
      content: ' ';
    }
    @include breakpoint($medium-up) {
      top: 25px;
    }
  }
  .slick-next {
    @include bordered-triangle(12px, $color-white, right, $color-black);
    left: 300px;
    top: 27px;
    position: absolute;
    &:before {
      content: ' ';
    }
    @include breakpoint($medium-up) {
      top: 25px;
      left: 220px;
    }
  }
}

.product-brief__shade-item {
  height: 28px;
  position: relative;
  width: 28px;
}

.product-brief__shade {
  @include cr18-shade-swatch;
  box-shadow: inset 0 0 0 3px $color-white;
  height: 28px;
  width: 28px;
  &:after {
    content: ' ';
    display: block;
    height: 21px;
    left: 3px;
    margin: 0;
    position: absolute;
    top: 3px;
    width: 21px;
  }
  &.inv-status--sold-out:after,
  &.inv-status--not-shoppable:after {
    @include cr18-shade--disabled;
  }
}
